import React from "react";
import { useTranslation } from "react-i18next";
import "../../translations/i18n";
import {getSocials} from "../../utils/Common";
const social = getSocials();
const FooterBar = (props) => {
    const { t } = useTranslation();
    const d = new Date();
    let year = d.getFullYear();
    return (
        <footer className="bg-middle">
            <div className="mx-auto max-w-md py-12 px-4 overflow-hidden sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
                <div className="mt-8 flex justify-center space-x-6">
                    {social.map((item) => (
                        <a key={item.name} href={item.href} className="text-gray-400 hover:text-gray-300" target="_blank"  rel="noreferrer">
                            <span className="sr-only">{item.name}</span>
                            <item.icon className="h-10 w-10" aria-hidden="true" />
                        </a>
                    ))}
                </div>
                <p className="mt-8 text-center text-xs md:text-sm text-gray-400">&copy; {year} {t("copyright")}</p>
            </div>
        </footer>
    );
};
export default FooterBar;
