import React from "react";
import Layout from "../components/layout/Layout";
import NavBar from "../components/navigations/NavBar";
import FootBar from "../components/navigations/FootBar";
import MiddleOne from "../components/navigations/MiddleOne";
import {useTranslation} from "react-i18next";
import "../translations/i18n";
//import Carousel from 'carousel-react-rcdev'
import {StaticImage} from "gatsby-plugin-image";
import {Carousel} from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

const Partner = () => {

    const {t} = useTranslation();
    return (
        <Layout pageTitle={t("our_partners")}>
            <div className="min-h-full bg-gray-50">
                <NavBar active={'partners'}/>
                <div className="px-4 py-20 sm:p-20 bg-custom-block">
                    <div
                        className="relative flex flex-col items-center max-w-2xl px-6 py-10 mx-auto text-center sm:my-15 lg:px-0">
                        <h1 className="max-w-2xl text-4xl font-normal tracking-tight text-white lg:text-5xl uppercase">{t("our")}</h1>
                        <h1 className="max-w-2xl text-4xl font-extrabold tracking-tight text-white lg:text-5xl uppercase">{t("customers")}</h1>
                    </div>
                </div>

                <div className="-mt-16 mb-36">
                    <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-none lg:px-8">
                        {/* Main 3 column grid */}
                        <div
                            className="grid grid-cols-1 gap-4 items-start sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 lg:gap-8">
                            {/* SABLUX */}
                            <div className="grid grid-cols-1 gap-4 mb-5">
                                <section aria-labelledby={'sablux-title'}>
                                    <div className="bg-white overflow-hidden shadow">
                                        <div className="p-6">
                                            <a title='SABLUX'
                                               key={'sablux-title'}
                                               href="#"
                                               target="_blank"
                                               rel="noreferrer"
                                            >
                                                <div aria-hidden="true">
                                                    <StaticImage src={'../../static/clients/sablux.jpg'}
                                                                 alt={'SABLUX'}
                                                                 className="object-cover object-center w-full h-full"
                                                                 width={550}
                                                                 height={210}
                                                    />
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </section>
                            </div>
                            {/* Box */}
                            <div className="grid grid-cols-1 gap-4 mb-5">
                                <section aria-labelledby={'box-title'}>
                                    <div className="bg-white overflow-hidden shadow">
                                        <div className="p-6">
                                            <a title='BOX'
                                               key={'bos-title'}
                                               href="#"
                                               target="_blank"
                                               rel="noreferrer"
                                            >
                                                <div aria-hidden="true">
                                                    <StaticImage src={'../../static/clients/bos.jpg'}
                                                                 alt={'SABLUX'}
                                                                 className="object-cover object-center w-full h-full"
                                                    />
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </section>
                            </div>
                            {/* fongip */}
                            <div className="grid grid-cols-1 gap-4 mb-5">
                                <section aria-labelledby={'sablux-title'}>
                                    <div className="bg-white overflow-hidden shadow">
                                        <div className="p-6">
                                            <a title='FONGIP'
                                               key={'fongip'}
                                               href="#"
                                               target="_blank"
                                               rel="noreferrer"
                                            >
                                                <div aria-hidden="true">
                                                    <StaticImage src={'../../static/clients/fongip.jpg'}
                                                                 alt={'SABLUX'}
                                                                 className="object-cover object-center w-full h-full"
                                                                 width={550}
                                                                 height={210}
                                                    />
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </section>
                            </div>
                            {/* Savana */}
                            <div className="grid grid-cols-1 gap-4 mb-5">
                                <section aria-labelledby={'savana-color'}>
                                    <div className="bg-white overflow-hidden shadow">
                                        <div className="p-6">
                                            <a title='SAVANA'
                                               key={'savana'}
                                               href
                                               target="_blank"
                                               rel="noreferrer"
                                            >
                                                <div aria-hidden="true">
                                                    <StaticImage src={'../../static/clients/savana.jpeg'}
                                                                 alt={'SAVANA'}
                                                                 className="object-cover object-center w-full h-full"
                                                                 width={550}
                                                                 height={210}
                                                    />
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </section>
                            </div>
                            {/* servitech */}
                            <div className="grid grid-cols-1 gap-4 mb-5">
                                <section aria-labelledby={'sablux-title'}>
                                    <div className="bg-white overflow-hidden shadow">
                                        <div className="p-6">
                                            <a title='servitech'
                                               key={'servitech'}
                                               href
                                               target="_blank"
                                               rel="noreferrer"
                                            >
                                                <div aria-hidden="true">
                                                    <StaticImage src={'../../static/clients/servitech.jpg'}
                                                                 alt={'SERVITECH'}
                                                                 className="object-cover object-center w-full h-full"
                                                                 width={550}
                                                                 height={210}
                                                    />
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </section>
                            </div>
                            {/* ADILA */}
                            <div className="grid grid-cols-1 gap-4 mb-5">
                                <section aria-labelledby={'ADILA'}>
                                    <div className="bg-white overflow-hidden shadow">
                                        <div className="p-6">
                                            <a title='ADILA'
                                               key={'adila'}
                                               href
                                               target="_blank"
                                               rel="noreferrer"
                                            >
                                                <div aria-hidden="true">
                                                    <StaticImage src={'../../static/clients/logo-adila.jpeg'}
                                                                 alt={'adila'}
                                                                 className="object-cover object-center w-full h-full"
                                                                 width={550}
                                                                 height={210}
                                                    />
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </section>
                            </div>
                            {/* UP */}
                            <div className="grid grid-cols-1 gap-4 mb-5">
                                <section aria-labelledby={'UP'}>
                                    <div className="bg-white overflow-hidden shadow">
                                        <div className="p-6">
                                            <a title='UP'
                                               key={'up'}
                                               href
                                               target="_blank"
                                               rel="noreferrer"
                                            >
                                                <div aria-hidden="true">
                                                    <StaticImage src={'../../static/clients/UP.jpg'}
                                                                 alt={'SABLUX'}
                                                                 className="object-cover object-center w-full h-full"
                                                                 width={550}
                                                                 height={210}
                                                    />
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </section>
                            </div>
                            {/* TALIX */}
                            <div className="grid grid-cols-1 gap-4 mb-5">
                                <section aria-labelledby={'TALIX'}>
                                    <div className="bg-white overflow-hidden shadow">
                                        <div className="p-6">
                                            <a title='TALIX'
                                               key={'talix'}
                                               href
                                               target="_blank"
                                               rel="noreferrer"
                                            >
                                                <div aria-hidden="true">
                                                    <StaticImage src={'../../static/clients/talix.png'}
                                                                 alt={'TALIX'}
                                                                 className="object-cover object-center w-full h-full"
                                                                 width={550}
                                                                 height={210}
                                                    />
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </section>
                            </div>
                            {/* INO */}
                            <div className="grid grid-cols-1 gap-4 mb-5">
                                <section aria-labelledby={'sablux-title'}>
                                    <div className="bg-white overflow-hidden shadow">
                                        <div className="p-6">
                                            <a title='INO'
                                               key={'ino'}
                                               href
                                               target="_blank"
                                               rel="noreferrer"
                                            >
                                                <div aria-hidden="true">
                                                    <StaticImage src={'../../static/clients/logo-ino.png'}
                                                                 alt={'SABLUX INO'}
                                                                 className="object-cover object-center w-full h-full"
                                                                 width={550}
                                                                 height={210}
                                                    />
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </section>
                            </div>
                            {/* SABLUX immo plus */}
                            <div className="grid grid-cols-1 gap-4 mb-5">
                                <section aria-labelledby={'sablux-title'}>
                                    <div className="bg-white overflow-hidden shadow">
                                        <div className="p-6">
                                            <a title='SABLUX IMMO PLUS'
                                               key={'immo-plus'}
                                               href
                                               target="_blank"
                                               rel="noreferrer"
                                            >
                                                <div aria-hidden="true">
                                                    <StaticImage src={'../../static/clients/logo-immo-plus.svg'}
                                                                 alt={'IMMO PLUS'}
                                                                 className="object-cover object-center w-full h-full"
                                                                 width={550}
                                                                 height={210}
                                                    />
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </section>
                            </div>
                            {/* SABLUX materiaux  */}
                            <div className="grid grid-cols-1 gap-4 mb-5">
                                <section aria-labelledby={'sablux-title'}>
                                    <div className="bg-white overflow-hidden shadow">
                                        <div className="p-6">
                                            <a title='SABLUX MATERIAUX'
                                               key={'immo-plus'}
                                               href
                                               target="_blank"
                                               rel="noreferrer"
                                            >
                                                <div aria-hidden="true">
                                                    <StaticImage src={'../../static/clients/sablux-materiaux.jpg'}
                                                                 alt={'SABLUX MATERIAUX'}
                                                                 className="object-cover object-center w-full h-full"

                                                    />
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </section>
                            </div>
                            {/* METALCO*/}
                            <div className="grid grid-cols-1 gap-4 mb-5">
                                <section aria-labelledby={'sablux-title'}>
                                    <div className="bg-white overflow-hidden shadow">
                                        <div className="p-6">
                                            <a title='METALCO'
                                               key={'metalco'}
                                               href
                                               target="_blank"
                                               rel="noreferrer"
                                            >
                                                <div aria-hidden="true">
                                                    <StaticImage src={'../../static/clients/metalco.jpg'}
                                                                 alt={'metalco'}
                                                                 className="object-cover object-center w-full h-full"
                                                                 width={550}
                                                                 height={210}
                                                    />
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="px-4 py-20 sm:p-20 bg-custom-block">
                    <div
                        className="relative flex flex-col items-center max-w-2xl px-6 py-10 mx-auto text-center sm:my-15 lg:px-0">
                        <h1 className="max-w-2xl text-4xl font-normal tracking-tight text-white lg:text-5xl uppercase">{t("our")}</h1>
                        <h1 className="max-w-2xl text-4xl font-extrabold tracking-tight text-white lg:text-5x uppercasel">{t("partners")}</h1>
                    </div>
                </div>
                {/* partners */}
                <div className="-mt-10 mb-20 2xl:mt-5 sm:w-full">
                    <div className="grid grid-cols-1 content-center h-48  lg:px-14 lg:max-w-none lg:px-8">
                        <Carousel autoPlay={true} infiniteLoop={true} className="center-item">
                            <StaticImage
                                src={'../../static/partenaires/epson.jpg'}
                                alt={'EPSON'}
                                className="object-cover object-center w-full h-full bg-white"
                            />
                            <StaticImage
                                src={'../../static/partenaires/lenovo.jpg'}
                                alt={'LENOVO'}
                                className="object-cover object-center w-full h-full bg-white sm:w-full"
                            />
                            <StaticImage
                                src={'../../static/partenaires/dlink.jpg'}
                                alt={'DLINK'}
                                className="object-cover object-center w-full h-full bg-white"
                            />
                            <StaticImage
                                src={'../../static/partenaires/hp.jpg'}
                                alt={'HP'}
                                className="object-cover object-center w-full h-full bg-white"
                            />
                            <StaticImage
                                src={'../../static/partenaires/apc.jpg'}
                                alt={'APC'}
                                className="object-cover object-center w-full h-full bg-white"
                            />
                            <StaticImage
                                src={'../../static/partenaires/dell.jpg'}
                                alt={'DELL'}
                                className="object-cover object-center w-full h-full bg-white"
                            />
                            <StaticImage
                                src={'../../static/partenaires/mikrotik.jpg'}
                                alt={'mikrotik'}
                                className="object-cover object-center w-full h-full bg-white"
                                width={550}
                                height={210}
                            />
                            <StaticImage
                                src={'../../static/partenaires/ubiquity.png'}
                                alt={'ubiquity'}
                                className="object-cover object-center w-full h-full bg-white"
                                width={550}
                                height={210}
                            />
                            <StaticImage
                                src={'../../static/partenaires/yealink.png'}
                                alt={'yealink'}
                                className="object-cover object-center w-full h-full bg-white"
                                width={550}
                                height={210}
                            />
                            <StaticImage
                                src={'../../static/partenaires/yeastar.jpg'}
                                alt={'yeastar'}
                                className="object-cover object-center w-full h-full bg-white"
                            />
                            {/* ECKO */}
                            <StaticImage src={'../../static/clients/ecko.jpg'}
                                         alt={'SABLUX'}
                                         className="object-cover object-center w-full h-full bg-white"
                                         width={550}
                                         height={210}
                            />
                            {/* Motion */}
                            <StaticImage src={'../../static/clients/motion.png'}
                                         alt={'MOTION'}
                                         className="object-cover object-center w-full h-full bg-white"
                                         width={550}
                                         height={210}
                            />
                        </Carousel>
                    </div>
                </div>
                <MiddleOne/>
                <FootBar active={'partner'}/>
            </div>

        </Layout>
    );
}

export default Partner;
