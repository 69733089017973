import React from "react";
import { useTranslation } from "react-i18next";
import "../../translations/i18n";
import Link from "gatsby-link";
import {StaticImage} from "gatsby-plugin-image";
const MiddleOne = () => {
    const { t } = useTranslation();
    return (
        <>
            <div className="bg-middle py-8 md:py-0 md:p-16">
                <div className="relative flex flex-col items-center max-w-4xl px-6 py-3 mx-auto text-center sm:py-6 lg:px-0">
                    <h1 className="max-w-2xl text-4xl font-extrabold tracking-tight text-white md:text-6xl uppercase">
                        {t("ready_to_go")},{" "}<span className="text-cyan-900">{t("one")}<br/> {t("project")}{" "}
                          </span>?
                    </h1>
                    <p className="mt-10 md:mt-20 mb-10 text-sm md:text-md text-gray-200 list-disc">
                          <span className="mt-2 list-iteme justify-center">{t("project_msg")}</span>
                    </p>
                    <Link to={'/contact'}
                        href="#"
                        className="inline-block px-4 md:px-8 py-3 mt-8 text-sm md:text-base font-medium bg-cyan-900 border border-transparent rounded-md text-slate-100 hover:bg-teal-600 uppercase"
                    >
                        {t("contact_us")}
                    </Link>
                </div>
                <div aria-hidden="true" className="bottom-0 overflow-hidden">
                    <StaticImage
                        src={"../../../static/footer.png"}
                        alt=""
                        className="object-cover object-center w-full h-full"
                    />
                </div>

            </div>
        </>
    );
}
export default MiddleOne;
